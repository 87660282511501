@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../../assets/variables.scss';

/* Шапка */
header {
    top: 0;   
    position: fixed;
    z-index: 5;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    height: 74px;
}

.menu-btn {
    display: none;
}

nav {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    padding-left: 5%;
}

/* Кнопки навигации */
.nav-items {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 50%;
    height: 100%;  
}

.item {
    @extend %cont-shared;
    height: 100%;
    width: 9%;
    padding-right: 8%;
    padding-left: 8%;
    
    p {
        font-family: 'Roboto';
        font-size: 17px;
        cursor: pointer;
        text-decoration: none;
        color: #444;
        user-select: none;

        &:hover{
            font-family: 'Roboto';
            font-size: 19px;
            cursor: pointer;
        }
    }
}

/* Строка поиска */
.nav-btns {
    position: absolute;
    @extend %cont-shared;
    justify-content: flex-end;
    width: 30%;
    height: 100%;
    right: 0;
}

.search-cont {
    @extend %cont-shared;
    height: 60%;
    right: 0;
}

.search-field {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 110px;
    height: 32px;
    background-color: rgba(204, 201, 201, 0.6);
    border-radius: 40px;

    &:hover {
        background-color: #b89cff;
    }
}

.search-input {
    @extend %cont-shared;
    height: 100%;
    width: 70%;

    input {
        margin-left: 10px;
        width: 80%;
        background-color: transparent;
        border: 0;
        font-size: 14px;
        user-select: none;

        &:focus {
            outline: none;
            font-size: 14px;
            text-align: left;
            color: #000;
        }
    }
}

.search-icon {
    height: 50%;
    width: 20%;
    background-image: url('../../images/icons/search.png');
    background-repeat: no-repeat;
    background-size: contain;
}

/* Кнопки профиля */
.nav-cont {
    @extend %cont-shared;
    height: 70%;
    right: 0;
    width: 100px;
    margin-right: 10%;
    margin-left: 3%;
}

.nav-element {
    @extend %cont-shared;
    cursor: pointer;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    margin-right: 6px;
    margin-left: 6px;

    &:hover {
        background-color: #b89cff;
    }
}

.kart {
    position: absolute;
    height: 26px;
    width: 26px;
    background-image: url('../../images/icons/cart.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.user {
    position: absolute;
    height: 20px;
    width: 20px;
    background-image: url('../../images/icons/user.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#current-page {
    color: #000;
    font-weight: bold;
}

.menu-cont {
    display: none;
}