@import '../../assets/variables.scss';

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 640px)
  and (-webkit-min-device-pixel-ratio: 2) 
{ 
    /* Шапка */
    header {
        background-color: #FFF;
        height: 40px;
    }

    /* Кнопка мобильного меню */
    .menu-btn {
        z-index: 6;
        display: flex;
        position: absolute;
        flex-direction: column;
        left: 15px;
        top: 5px;
        width: 37px;
        height: 30px;
    }

    /* Кнопка открыть */
    .open-btn {
        margin-top: 5px;  
    }

    .menu-line {
        width: 34px;
        border: 1px solid #000;
        border-radius: 20px;
    }

    .line-middle {
        margin-top: 6px;
        width: 27px;
    }

    .line-bottom {
        margin-top: 6px;
        width: 20px;
    }
    
    /* Кнопка закрыть */
    .close-btn {
        margin-top: -10px;
        margin-left: 2px;
    }

    .cross-line {
        position: absolute;
        width: 30px;
        height: 0px;
        border: 1px solid #000;
        border-radius: 20px;
    }

    .cross-left {
        transform: rotate(45deg);
    }

    .cross-right {
        transform: rotate(-45deg);
    }

    /* Контейнер кнопок навигации */
    nav {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        padding-left: 5%;
    }

    /* Кнопки навигации */
    .nav-items {
        display: none;
    }

    /* Строка поиска */
    .nav-btns {
        display: none;
    }

    .menu-cont {
        z-index: 5;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        left: 0;
        background-color: #FFF;
    }

    .menu-nav {
        display: flex;
        position: fixed;
        flex-direction: column;
        align-items: center;
        width: 60%;
        height: 270px;
        margin-top: -85%;

        a {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            color: #444;
            margin-top: 25px;
            text-decoration: none;
        }
    }

    .menu-icons-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .nav-element {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        margin-left: 10px;
        background-color: #FFF;
    }

    .search-icon {
        width: 100%;
        height: 100%;
        margin-top: 80%;
    }

    .kart {
        width: 70%;
        height: 70%;
    }

    .user {
        width: 50%;
        height: 50%;
    }
}