@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 640px)
  and (-webkit-min-device-pixel-ratio: 2)
{
    .text-lines-cont {
        flex-direction: row;
    }

    .text-line {
        flex-direction: column;
        margin-top: 2%;
        height: 100%;
        align-items: flex-start;
        
    }

    .first-line {
        width: 60%; 
        margin-left: 10%;
    }

    .first-line a {
        font-weight: 400;
        font-size: 20px;
        margin-top: 5px;
        
    }

    .second-line {    
        width: 40%;
        margin-right: 5%;
    }

    .second-line a {
        margin-top: 5px;
        line-height: 26px;
    }

    .contacts{
        width: 100%;
        height: 62px;
        margin-top: 7%;
    }

    .icon-cont{
        height: 36px;
        width: 36px;
        margin-left: 5%;
    }

    .icon{
        padding: 0;
        width: 100%;
    }

    #icon2{
        margin-top: 20%;
    }
}