@import '../../assets/variables.scss';

.item-cont {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 100%;
    height: 66px;

    p {
        @extend %p-shared;
        font-weight: 300;
        font-size: 20px;
    }
}

.number {
    @extend %cont-shared;
    width: 50px;
    height: 100%;
}

.item-image-cont {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 100px;
    height: 100%;
    // background-color: yellow
}

.cart-item-image {
    @extend %cont-shared;
    width: 100px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain
}

.cart-item-title {
    @extend %cont-shared;
    justify-content: flex-start;
    // background-color: pink;
    padding-left: 16px;
    width: 20%;
    height: 100%;
    p {
        @extend %p-shared;
        font-weight: lighter;
        font-size: 20px;
    }
}

.item-price {
    @extend %cont-shared;
    justify-content: flex-end;
    width: 45%;
    height: 100%;
    // background-color: green;
}

.delete-button-cont {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 20%;
    height: 100%;
    padding-left: 30px;
}

.delete-button {
    cursor: pointer;
}

.total-price {
    p {
        @extend %p-shared;
        font-weight: 400;
        font-size: 26px;
    }
}