@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap');
@import '../assets/variables.scss';

/* Слайдер товаров */
.slider-cont {
  @extend %cont-shared;
  width: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.slider-box {
  @extend %cont-shared;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  width: 30%;
  height: 100%;
}

.image-box {
  @extend %cont-shared;
  width: 120%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

.title-cont {
  @extend %cont-shared;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 10%;

  span {
    font-family: 'Roboto';
    font-size: 18px;
    cursor: pointer;
  }
}

/* Картинки товаров в слайдере */
#box1 {
  background-image: url('../images/slider/nike1.png');
}

#box2 {
  background-image: url('../images/slider/nike2.png');
}

#box3 {
  background-image: url('../images/slider/nike3.png');
}

#box4 {
  background-image: url('../images/slider/nike4.png');
}

#box5 {
  background-image: url('../images/slider/nike5.png');
}

#box6 {
  background-image: url('../images/slider/nike6.png');
}

#box7 {
  background-image: url('../images/slider/nike7.png');
}

#box8 {
  background-image: url('../images/slider/nike8.png');
}

#box9 {
  background-image: url('../images/slider/nike9.png');
}

/* Раздел скидки (только моб. вер.) */
.sale-orange-cont {
  display: none;
}

/* Промо раздел */
.promo-cont {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(90deg, #ffc165 75%, #fff 0) var(--_p, 100%) / 200%
    no-repeat;
  transition: 0.8s;
}

.promo-cont-active {
  --_p: 78%;
}

.top-cont {
  @extend %cont-shared;
  height: 80%;
  width: 100%;
}

.bottom-cont {
  @extend %cont-shared;
  height: 20%;
  width: 100%;
}

.third-box {
  @extend %cont-shared;
  flex-direction: column;
  height: 100%;
  width: 33.33%;
}

.brand-name-cont {
  @extend %cont-shared;
  width: 70%;
  margin-bottom: -5%;
}

.shadow-text {
  font-size: 120px;
  letter-spacing: 0.28em;
  margin-left: 20px;
  text-align: center;
  color: #fcedd8;
  font-family: 'Nerko One';
  font-weight: 700;
  text-shadow: 5px 5px 0px #eb452b, 10px 10px 0px #efa032, 15px 15px 0px #46b59b,
    20px 20px 0px #017e7f, 25px 25px 0px #052939, 30px 30px 0px #c11a2b;
}

.brand-title-cont p {
  font-family: 'Anton';
  font-size: 70px;
  color: #fff;
}

/* Кнопка details */
.details-button {
  @extend %cont-shared;
  width: 25%;
  height: 50px;
  margin-left: 19%;
}

.details-button-span-cont {
  @extend %cont-shared;
  height: auto;
  width: fit-content;
  border: none;
  background-color: transparent;
  border-radius: 5px;

  p {
    font-family: 'Roboto';
    font-size: 28px;
    color: #000;
    cursor: pointer;
    user-select: none;
    box-shadow: inset 0 0 0 0 #000;
    border-radius: 5px;
    margin: 0 -0.25rem;
    padding: 0 0.25rem;
    transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: inset 0 100px 0 0 #000;
      border-radius: 5px;
      color: #fff;
    }
  }
}

/* Фото промо товара */
.main-image {
  position: relative;
  display: flex;
  width: 110%;
  height: 110%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-position-y: 45%;
  transform: rotate(-30deg);
}

.third-box-cont {
  @extend %cont-shared;
  width: 100%;
  height: 50%;
}

.span-top {
  @extend %cont-shared;
  margin-right: -20%;

  span {
    font-family: 'Anton';
    font-size: 64px;
    color: #000;
    margin-bottom: -55%;
  }
}

.span-bottom {
  display: none;
}

/* Промо товары */
.rounded-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 100px;
  margin-left: 2%;
  margin-right: 2%;
}

.rounded-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 224px;
  height: 81px;
  margin-left: 5%;
  margin-right: 5%;
  background-color: transparent;
  border-radius: 31px;
  box-shadow: 9px 9px 24px 1px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  cursor: pointer;
}

.rounded-item-active {
  width: 265px;
  height: 95.4px;
}

.photo-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.photo {
  position: absolute;
  margin-left: 6%;
  width: 124px;
  height: 68px;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Картинки товаров в промо разделе */
#ph1 {
  background-image: url('../images/shoes/nike11.png');
}

#ph2 {
  background-image: url('../images/shoes/nike12.png');
}

#ph3 {
  background-image: url('../images/shoes/nike13.png');
}

#ph4 {
  background-image: url('../images/shoes/nike14.png');
}

.price {
  margin-left: 30%;
  width: 40%;

  p {
    font-family: 'Roboto';
    font-size: 20px;
    color: #000;
  }
}

/* 3Д карусель */
.object-cont {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(90deg, #fff 65%, #65a8a8 0%) var(--_p, 100%) /
    200% no-repeat;
  transition: 0.8s;
}

.object-cont-active {
  --_p: 80%;
}

.object-cont-left {
  @extend %cont-shared;
  flex-direction: column;
  height: 100%;
  width: 30%;
}

.button-cont {
  @extend %cont-shared;
  width: 60%;

  span {
    width: 58%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.04em;
    color: #000;
    user-select: none;
  }
}

.button-circle {
  @extend %cont-shared;
  margin-left: 0;
  width: 32px;
  height: 32px;
  border: 1px solid #000;
  border-radius: 100% 100% 100% 100%;
  cursor: pointer;

  &:hover {
    background-color: #000;
  }

  &:hover #circledButton {
    color: #fff;
  }
}

/* Ленты с надписями */
.sale-string-cont {
  @extend %cont-shared;
  flex-direction: column;
  width: 100%;
  height: 150px;
  margin-top: -30px;
}

.sale-string {
  position: absolute;
  @extend %cont-shared;
  height: 55px;
  width: 100%;
  overflow: hidden;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #000;
    user-select: none;
  }
}

#blue-string {
  margin-bottom: -50px;
  background: #79f2fa;
  transform: rotate(-0.78deg);
}

#corral-string {
  margin-bottom: 50px;
  background: #ffa588;
  transform: rotate(2.23deg);
}

#green-string {
  margin-bottom: 50px;
  background: #c5f38b;
  transform: rotate(-1.77deg);
}

/* Баскетбольная коллекция */
.basketball-cont {
  @extend %cont-shared;
  flex-direction: column;
  margin-top: 3%;
  width: 100%;

  h2 {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.105em;
    color: #000;
  }
}

.basketball-image {
  @extend %cont-shared;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 2%;
  width: 1224px;
  height: 688px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../images/shoes/basketball.jpg');

  button:hover {
    width: 160px;
    height: 37px;
    font-size: 21px;
  }
}

.basketball-image-button-cont {
  @extend %cont-shared;
  margin-bottom: 2%;
  width: 160px;
  height: 37px;
}

/* Форма обратной связи */
.form-container {
  @extend %cont-shared;
  margin-top: 7%;
  width: 80.5%;
  max-width: 1224px;
  height: 295px;
}

.form {
  @extend %cont-shared;
  flex-direction: column;
  width: 59.9%;
  height: 100%;
  background-color: #eac794;
}

.form-title-cont {
  position: relative;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  margin-top: 0;
  width: 75%;
  height: 60%;

  span {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.105em;
    color: #fff;
  }
}

.form-input-cont {
  @extend %cont-shared;
  width: 75%;
  height: 18%;
  background-color: #fff;
}

.input-cont {
  @extend %cont-shared;
  width: 70%;
  height: 100%;

  input {
    width: 96%;
    height: 75%;
    border: none;
    font-size: 16px;

    &::placeholder {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: left;
      color: #888;
      user-select: none;
    }

    &:focus {
      outline: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: left;
      color: #000;
    }
  }
}

.form-button {
  @extend %cont-shared;
  border: none;
  width: 30%;
  height: 100%;
  cursor: pointer;
  background: linear-gradient(90deg, #3d9282 50%, #92703d 0) var(--_p, 100%) /
    200% no-repeat;
  transition: 0.4s;

  &:hover {
    --_p: 0%;
  }

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #fff;
    user-select: none;
  }
}

.form-image-cont {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 45%;
  background-image: url('../images/shoes/run.jpeg');
}
