@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap');

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 640px)
  and (-webkit-min-device-pixel-ratio: 2) 
{
  /* Слайдер */
  .title-cont span {
    font-size: 14px;
  }

  /* Раздел скидки (только моб. вер.) */
  .sale-orange-cont {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFC165;
    width: 100%;
    height: 78px;
  }
    
  .sale-first-line {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    letter-spacing: 0.205em;
    color: #FFF;
  }

  .sale-second-line {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.205em;
    color: #000;
  }

  /* Промо раздел */
  #left-third-cont{
    display: none;
  }

  .promo-cont {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;
    background: #FFF;
  }
  
  .top-cont {
    height: 80%;
  }

  .bottom-cont {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 20%;
  }
  
  .third-box {
    width: 50%;
  }
  
  /* Кнопка details */
  .details-button {
    width: 95px;
    height: 36px;
    margin-left: 45px;

    p {
      font-family: 'Roboto';
      font-size: 22px;
      color: #000;
      cursor: pointer;
      user-select: none;
      box-shadow: none;
      border-radius: 5px;
      margin: 0 -.25rem;
      padding: 0 .25rem;
      transition: none;

      &:hover {
        box-shadow: none;
        border-radius: none;
        color: #000;
      }
    }
  }
  
  .details-button-span-cont {
    border-radius: 14px;
    height: 36px;
    width: 95px;
    background-color: rgba(217, 217, 217, .35);
  }
  
  /* Фото промо товара */
  .main-image {
    max-height: 220px;
  }

  .third-box-cont {
    flex-direction: column;
    height: 100%;
  }
  
  .span-top {
    margin-top: 30%;
    margin-left: 20px;
    margin-right: 0;

    span {
      font-size: 36px;
      margin-bottom: 0;
      margin-right: 0;
      
    }
  }

  .span-bottom {
    display: flex;
    position: relative;
    margin-left: 86px;

    span {
      font-family: 'Roboto';
      font-size: 22px;
      color: #000;    
    }
  }
  
  /* Промо товары */
  .rounded-item {
    width: 71px;
    height: 71px;
    border-radius: 100%;
    box-shadow: none;
  }
  
  .rounded-item-active {
    background-color: rgba(206, 206, 206, .1  );
    box-shadow: 9px 9px 24px 1px rgba(0, 0, 0, .25  );
  }
  
  .photo-cont {
    width: inherit;
    height: inherit;
  }
  
  .photo {
    width: inherit;
    height: inherit;
    background-position: center;
    margin-left: 0;
  }
  
  .price {
    display: none;
  }

  /* 3Д карусель */
  .object-cont {
    height: 435px;
    background: #65A8A8;
    transition: none;
  }

  .object-cont-left {
    display: none;
  }

  /* Ленты с надписями */
  .sale-string-cont {
    height: 75px;
  }

  .sale-string {
    height: 27px;
  }

  #blue-string {
    margin-bottom: -25px;
  }

  #corral-string {
    margin-bottom: 25px;
  }

  #green-string {
    margin-bottom: 25px;
  }

  /* Баскетбольная коллекция */
  .basketball-cont {
    h2 {
      font-size: 28px;
    }
  }

  .basketball-image {
    width: 612px;
    height: 344px;

    button {
      &:hover {
        width: 147px;
        height: 34px;
        font-size: 18px;
      }
    }
  }

  /* Форма обратной связи */
  .form-container {
    width: 100%;
  }

  .form {
    z-index: 3;
    position: absolute;
    width: 100%;
    background-color: transparent;
  }

  .form-title-cont {
    width: 85%;
    height: 40%;

    span {
      font-size: 28px;
      line-height: 46px;
    }
  }

  .form-input-cont {
    width: 85%;
  }

  .form-button {
    width: 35%;
    background: #92703D;
    transition: none;

    span {
      font-size: 18px;
    }
  }

  .form-image-cont {
    width: 100%;
  }

}