.my-button {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 147px;
    height: 34px;
    border-radius: 31px;
    border:  none;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    user-select: none;
    text-align: center;
    color: rgb(46, 46, 46);
}