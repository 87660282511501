/* Подвал */
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 330px;
    width: 100%;
    background-color: transparent;
    margin-top: 10%;
}

hr {
    height: 1px;
    background-color: #8e8e8e;
    border: none;
    width: 90%;
}

#hr-bottom {
    margin-top: 3%;
}

.text-lines-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.text-line {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 40%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2.5%;
}

.first-line {
    margin-top: 2.5%;
}

.first-line a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.105em;
    color: #000000;
    text-decoration: none;
    margin-left: 2.6%;
    margin-right: 2.6%;
    margin-top: 0;
    user-select: none;
}

.second-line {
    margin-top: 1%;
}

.second-line a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.105em;
    color: #000;
    text-decoration: none;
    margin-left: 1.6%;
    margin-right: 1.6%;
    user-select: none;
}

.contacts{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 5%;
    right: 0;
    align-self: center;
    margin-top: 2%;
}

.icon-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 33%;
    margin-right: 5%;
}

.icon{
    padding: 15%;
    position: absolute;
    cursor: pointer;
    width: 80%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon a{
    width: 80%;
    height: 100%;
    text-decoration: none;
}

#icon1{
    background-image: url('../../images/logos/insta.png');
}

#icon2{
    background-image: url('../../images/logos/twitter.png');
}

#icon3{
    background-image: url('../../images/logos/facebook.png');
}

.mark-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
}