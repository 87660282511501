@import '../assets/variables.scss';

.stikers-cont {
    @extend %cont-shared;
    justify-content: flex-start;
    height: 60px;
    width: 81%;
    margin-top: 74px;

    // background-color: pink;
}

.stiker {
    @extend %cont-shared;
    height: 60px;
    width:  fit-content;
    height: 20px;
    padding-left: 7px;
    padding-right: 7px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 30px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    }
}

.stiker_sale {
    border: 1px solid #FFC165;

    p {
        letter-spacing: 0.045em;
        color: #FFC165;
    }
}

.stiker_new {
    border: 1px solid #65A8A8;

    p {
        letter-spacing: 0.195em;
        color: #65A8A8;
    }
}

.rating-cont {
    @extend %cont-shared;
    justify-content: flex-start;
    height: 76px;
    width: 81%;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #444;
        margin-left: 8px;
    }
}

.rating-star {
    width: 20px;
    height: 20px;
    color: #FFE600;
    margin-left: 5px;
}

.product-main-cont {
    @extend %cont-shared;
    height: 470px;
    width: 81%;
    // border: 1px solid black;
}

.product-image-cont {
    @extend %cont-shared;
    align-items: flex-start;
    height: 100%;
    width: 560px;
    // background-color: yellow;
}

.product-image {
    background-image: url('../images/shoes/nike1.png');
    // background-color: red;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 558px;
    height: 354px;
}

.order-cont {
    @extend %cont-shared;
    justify-content: flex-end;
    height: 100%;
    width: 560px;
}

.order-cont_right {
    @extend %cont-shared;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 220px;
    // border: 1px solid black;    
}

.order-title {
    @extend %cont-shared;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    
    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        color: #000;
    }
}

.order-first {
    font-size: 26px;
}

.order-second {
    font-size: 30px;
}

.order-tool-cont {
    @extend %cont-shared;
    height: 51px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.order-tool_left {
    justify-content: flex-start;
}

.order-tool_right {
    justify-content: flex-end;
}

.rounded-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 100%;
    border-radius: 30px;
    margin-left: 0;
    margin-right: 0;
}

.order-price {
    background: #FFC165;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 0.055em;
        color: #FFF;
    }
}

.add-cart {
    background: #DFDFDF;
    cursor: pointer;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #000000;
    }
}

.choose-size {
    @extend %cont-shared;
    width: 220px;
    height: 100%;
    border: 1px solid #000;
    border-radius: 31px;
    cursor: pointer;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.085em;
        color: #000;
        margin-right: 32px;
    }
}

.information-cont {
    display: flex;
    width: 81%;
    height: auto;
}

.information-cont_half {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    // border: 1px solid black;
}

.swith-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 70px;
    // border: 1px solid black;
}

.info-button {
    display: block;
    width: 142px;
    height: 52px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        letter-spacing: 0.18em;
        color: #000;
    }
}

.info-button_active {
    border-bottom: 1px solid #000;
}

.info-button_right {
   margin-left: 50px; 
}

.main-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    width: 100%;
    margin-top: 30px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        letter-spacing: 0.12em;
        color: #000;
    }
}

.info-columns-cont {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.info-column {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.info-column_item {
    display: flex;
    position: relative;
    width: 100%;
    height: 48px;
    align-items: center;
    justify-content: flex-start;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #444;
    }
}

.info-brand {
    p {
        text-align: justify;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #444;    
    }
}

.learn-more-bitton {
    display: flex;
    width: fit-content;
    height: 50px;
    border-bottom: 1px solid #65A8A8; 
    cursor: pointer;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #65A8A8;
    }
}