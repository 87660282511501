@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 640px)
  and (-webkit-min-device-pixel-ratio: 2) 
{
    /* 3Д карусель */
    .object-cont-right {
        width: 100%;
        height: 435px;
    }

    .canvas-cont {
        height: 55%;
        width: 80%;
        margin-top: -75px;
        margin-right: 20%;
    }

    .radius {
        width: 150px;
        height: 300px;
        margin-top: -75px;
    }

    .box-dot {
        height: 26px;
        width: 26px;
    }

    .dot {
        height: 14px;
        width: 14px;
    } 

    .dot-active {
        height: 22px;
        width: 22px;
    }

    .object-cont-tools{
        display: none;
    }

    #box-dot-top {
        margin-top: -9px;
        margin-left: 15%;
    }

    #box-dot-middle {
        margin-top: 90%;
        margin-left: 92%;
    }

    #box-dot-bottom {
        margin-top: 190%;
        margin-left: 15%;
    }

    #mobile-only{
        display: flex;
    }
}