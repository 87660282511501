@import '../assets/variables.scss';

.fast-filter {
    @extend %cont-shared;
    height: 482px;
    width: 100%;
    margin-top: 7vh;
}

.fast-cont {
    @extend %cont-shared;
    margin-top: 50px;
    width: 1120px;
    height: 286px;
}

.fast-filter-item {
    @extend %cont-shared;
    flex-direction: column;
    justify-content: flex-start;
    width: 314px;
    height: 286px;
    margin-left: 28px;
    margin-right: 28px;
}

.fast-filter-item_image {
    display: flex;
    width: 100%;
    height: 230px;
    border-radius: 15px;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

#fast-men {
    background-image: url('../images/filters/men.webp');
}

#fast-women {
    background-image: url('../images/filters/women.webp');
}

#fast-children {
    background-image: url('../images/filters/children.jpeg');
}

.fast-filter-item_title {
    @extend %cont-shared;
    align-items: flex-start;
    width: 47%;
    height: 56px;
    border-bottom: 3px solid black;

    p {
        @extend %p-shared;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.25em;
        cursor: pointer;
    }
}

.catalog-cont {
    @extend %cont-shared;
    align-items: flex-start;
    width: 100%;
    height: auto;
}

.filters-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 314px;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
}

.filter {
    @extend %cont-shared;
    flex-direction: column;
    width: 50%;
    height: fit-content;
}

.filter-title {
    @extend %cont-shared;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #838383;
    justify-content: flex-start;

    p {
        @extend %p-shared;
        font-weight: 600;
        font-size: 22px;
        cursor: pointer;
    }
}

.filter-body {
    @extend %cont-shared;
    flex-direction: column;
    width: 100%;
}

.filter-item {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 100%;
    height: 36px;

    p {
        @extend %p-shared;
        font-weight: 300;
        font-size: 18px;
        cursor: pointer;

        &:hover {
            color: #7a7a7a;
        }
    }
}

.catalog {
    @extend %cont-shared;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    // background-color: pink;
}

.catalog-sort {
    @extend %cont-shared;
    width: fit-content;
    height: 36px;
    margin-top: 14px;
    padding-left: 4px; 
    padding-right: 4px; 
    margin-left: 40px;
    cursor: pointer;

    p {
        @extend %p-shared;
        font-weight: 400;
        font-size: 18px;
    }
}

.choose-sort {
    @extend %cont-shared;
    margin-left: 4px;
    margin-top: 2px
}

.catalog-body-cont {
    @extend %cont-shared;
    width: 100%;
    height: auto;
}

.catalog-body {
    @extend %cont-shared;
    flex-direction: column;
    width: 100%;
    height: auto;
    // background-color: red;
}

.catalog-goods-row {
    @extend %cont-shared;
    justify-content: flex-start;
    width: calc(100% - 100px);
    box-sizing: border-box;
    height: 240px;
    margin-top: 20px;
    margin-bottom: 20px;
    // background-color: yellow;
}
