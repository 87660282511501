@import '../../assets/variables.scss';

.main-cont {
  @extend %cont-shared;
  flex-direction: column;
  justify-content: flex-start;
  width: 99vw;
  height: 53vh;
}

.carousel {
  @extend %cont-shared;
  width: 100%;
  height: 85%; 
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.all-items-cont {
  @extend %cont-shared;
  justify-content: flex-start;
  width: inherit;
  height: inherit;

  transition: translate;
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
}

/* Стрелки */
.arrow-cont {
  @extend %cont-shared;
  flex-direction: column;
  width: 8%;
  height: 100%;
}

.arrow {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #8B8B8B;
}

/* Указатели слайдов */
.circles-cont {
  @extend %cont-shared;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 15%;
}

.circles {
  @extend %cont-shared;
  width: 15%;
  height: 80%;
  margin-bottom: 2%;
}

.round {
  z-index: 1;
  position: relative;
  cursor: pointer;
  display: flex;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 7px;
  margin-left: 7px;
  background-color: #D9D9D9;
}

.round-active {
  width: 12px;
  height: 12px;
}
