/* Шрифты */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../assets/variables.scss';

/* Основные блоки */
body {
    margin: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
        // background-color: red;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgb(192, 192, 192);
    }
}


.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    overflow-x: hidden;
}

.dark-theme {
    background-color: rgb(37, 39, 54);
}


