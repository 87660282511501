@import '../../assets/variables.scss';

.catalog-item {
 @extend %cont-shared;
 flex-direction: column;
 justify-content: flex-start;
 min-width: 240px;
 width: 240px;
 height: 240px;
//  background-color: pink;
 margin-left: 2%;
 margin-right: 2%;
}

.item-image {
    width: 240px;
    height: 160px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}

.item-about {
    @extend %cont-shared;
    flex-direction: column;
    width: 100%;
    height: 80px;
}

.item-about_title {
    @extend %cont-shared;
    width: 100%;
    height: 40px;

    p {
        @extend %p-shared;
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
    }
}

.item-about_price {
    @extend %cont-shared;
    width: 100%; 
    height: 40px;

    p {
        @extend %p-shared;
        font-weight: 300;
        font-size: 18px;
        cursor: pointer;
    }
}