%cont-shared {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

%p-shared {
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    // color: #000;
}