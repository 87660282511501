@import '../assets/variables.scss';

.mid-column {
    @extend %cont-shared;
    align-items: flex-start;
    width: 81%;
    height: fit-content;
    margin-top: 154px;
}

.items-list-cont {
    @extend %cont-shared;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: 274px;
}

.items-list_title {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 100%;
    height: 24px;

    p {
        @extend %p-shared;
        font-weight: 400;
        font-size: 20px;
    }
}

.items-list {
    @extend %cont-shared;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 26px;
}

.submit-form {
    @extend %cont-shared;
    flex-direction: column;
    width: 230px;
    height: 274px;
    background-color: #F4F4F4;
    border-radius: 30px;
}

.cart-title {
    @extend %cont-shared;
    justify-content: flex-start;
    width: 80%;
    height: 50px;
    // background-color: red;

    p {
        @extend %p-shared;
        font-weight: 400;
        font-size: 22px;
    }
}

.count {
    height: 26px;

    p {
        @extend %p-shared;
        font-weight: 300;
        font-size: 18px;
    }
}

.promo-button {
    border-bottom: 1px solid #C9C9C9;
    p {
        font-weight: 400;
        font-size: 18px;
        color: #FFC165;
        cursor: pointer;
    }
}

.submit-button {
    @extend %cont-shared; 
    width: 80%;
    height: 41px;
    border-radius: 30px;
    background-color: #FFC165;
    cursor: pointer;

    p {
        @extend %p-shared;
        font-weight: 300;
        font-size: 18px;
        color: #fff;
    }
}