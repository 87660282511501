@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 640px)
  and (-webkit-min-device-pixel-ratio: 2)
{
    .main-cont {
        justify-content: center;
        width: 100%;
        height: 132px;
    }
      
    .carousel {
        height: 98px; 
    }
      
    /* Указатели слайдов */
    .circles-cont {
        display: none;
    }
      
}